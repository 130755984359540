// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/codebuild/output/src899573444/src/teachersplusfundweb/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/codebuild/output/src899573444/src/teachersplusfundweb/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-donation-complete-js": () => import("/codebuild/output/src899573444/src/teachersplusfundweb/src/pages/DonationComplete.js" /* webpackChunkName: "component---src-pages-donation-complete-js" */),
  "component---src-pages-elements-js": () => import("/codebuild/output/src899573444/src/teachersplusfundweb/src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-generic-js": () => import("/codebuild/output/src899573444/src/teachersplusfundweb/src/pages/generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-goal-2019-js": () => import("/codebuild/output/src899573444/src/teachersplusfundweb/src/pages/Goal2019.js" /* webpackChunkName: "component---src-pages-goal-2019-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src899573444/src/teachersplusfundweb/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("/codebuild/output/src899573444/src/teachersplusfundweb/src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-teacher-salary-js": () => import("/codebuild/output/src899573444/src/teachersplusfundweb/src/pages/teacher-salary.js" /* webpackChunkName: "component---src-pages-teacher-salary-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/codebuild/output/src899573444/src/teachersplusfundweb/.cache/data.json")


module.exports = [{
      plugin: require('/codebuild/output/src899573444/src/teachersplusfundweb/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src899573444/src/teachersplusfundweb/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-148102048-1","head":false,"respectDNT":true,"pageTransitionDelay":0,"sampleRate":100,"siteSpeedSampleRate":10,"cookieDomain":"www.teachersplusfund.org"},
    },{
      plugin: require('/codebuild/output/src899573444/src/teachersplusfundweb/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
